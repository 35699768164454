import Page from "components/Page";
import { Route, Routes, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FETCH_ASSESSMENT_DETAIL } from "./graphql";
import Spinner from "components/Spinner";
import AssessmentCategory from "./AssessmentCategory";
import AssessmentBaseInfo from "./AssessmentBaseInfo";
import Errors from "components/Errors";

const AssessmentDetail = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_ASSESSMENT_DETAIL, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const assessment = data.assessment;
  if (!assessment)
    return <Errors error={{ message: "Assessment not found" }} />;

  const baseInfo = JSON.parse(assessment.baseInfo);

  return (
    <Page
      title={assessment.category.name}
      subtitle={baseInfo ? baseInfo.name : null}
      backTo="../"
    >
      <Routes>
        <Route
          path="0"
          element={<AssessmentBaseInfo assessment={assessment} />}
        />
        <Route
          path=":index"
          element={<AssessmentCategory assessment={assessment} />}
        />
      </Routes>
    </Page>
  );
};

export default AssessmentDetail;
