import { useMutation, useQuery } from "@apollo/client";
import Page from "components/Page";
import { FETCH_ASSESSMENT } from "./graphql";
import { useParams } from "react-router-dom";
import Spinner from "components/Spinner";
import AssessmentStatusChain from "./AssessmentStatusChain";
import CategoryQuestions from "./CategoryQuestions";
import { createContext, useState } from "react";
import Button from "components/Button";
import { GENERATE_DOCUMENT } from "graphql/generateDocuments";
import { toast } from "react-toastify";
import http from "http";

export const AssessmentQuestionContext = createContext({});

const STATUS_TEXT = {
  REVIEWING:
    "This assessment is under review. We will come back to you if we have any questions. 这个评估正在审核中. 如果我们有任何问题, 我们会与您联系.",
  IMPROVING: `This assessment is now in improvement stage, only the questions required improvement are displayed. Please submit your improvement for each
  question below. If the answer should be updated after improvement, please input the updated answer. If you have any questions, please contact us.
  这个评估现在处于整改阶段. 请在下面提交您的整改方案. 如果整改后答案应该更新, 请在下面输入更新后的答案. 如果您有任何问题, 请联系我们.`,
  COMPLETED:
    "This assessment is completed. Thank you for your participation. 这个评估已经完成. 感谢您的参与.",
};

const AssessmentFollow = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_ASSESSMENT, {
    variables: { id },
  });
  const [downloading, setDownloading] = useState(false);
  const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
    onError: (error) => toast.error(error.message),
  });

  if (loading) return <Spinner />;
  if (error) return <div>{error.message}</div>;

  const questions = data.assessment.questions.edges
    .filter((i) => {
      if (data.assessment.status === "IMPROVING")
        return i.node.requiredImprovement && !i.node.improvementCompleted;
      return true;
    })
    .map((i) => i.node);

  function getAllCategoryQuestions(category) {
    let result = [];
    if (category.children) {
      category.children.edges.forEach(({ node }) => {
        result = result.concat(getAllCategoryQuestions(node));
      });
    }

    result = result.concat(
      questions.filter((question) => {
        return question.question.category.id === category.id;
      }),
    );

    return result;
  }

  console.log("data.assessment.category", data.assessment.category);

  function buildCategoriesTree(category) {
    let result = [];
    if (category.children) {
      category.children.edges.forEach(({ node }) => {
        if (node.requiredSupplier)
          result.push({
            ...node,
            children: buildCategoriesTree(node).filter(
              (i) => i.questions.length > 0,
            ),
            questions: getAllCategoryQuestions(node),
          });
      });
    }
    return result;
  }

  const categories = buildCategoriesTree(data.assessment.category).filter(
    (category) => {
      // if (data.assessment.status === "IMPROVING") {
      //   return category.questions.length > 0;
      // }

      return true;
    },
  );

  console.log("categories", categories);

  async function downloadAssessment() {
    try {
      setDownloading(true);
      const name = `${data.assessment.supplier.name} - ${data.assessment.category.name}`;
      const res = await generateDocument({
        variables: {
          name,
          docType: "assessment",
          data: JSON.stringify({ id: data.assessment.id }),
        },
      });
      http
        .get(
          `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?pdf=1`,
          {
            responseType: "blob",
            withCredentials: true,
          },
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name + ".pdf");
          document.body.appendChild(link);
          link.click();
          setDownloading(false);
        });
    } catch (error) {
      toast.error(error.message);
      setDownloading(false);
    }
  }

  const baseInfo = JSON.parse(data.assessment.baseInfo);

  return (
    <Page
      title={data.assessment.category.name}
      subtitle={baseInfo?.name}
      backTo="../"
      topbarRightView={
        <div>
          <Button onClick={downloadAssessment} loading={downloading}>
            Download
          </Button>
        </div>
      }
    >
      <div className="mx-4 xl:mx-8 mt-4 xl:mt-8">
        <AssessmentStatusChain status={data.assessment.status} />
      </div>

      <div className="mx-6 xl:mx-10 opacity-70 text-sm my-4">
        {STATUS_TEXT[data.assessment.status]}
      </div>
      <AssessmentQuestionContext.Provider
        value={{ assessmentStatus: data.assessment.status }}
      >
        <div className="mx-6 xl:mx-10 mb-10 mt-0 space-y-12">
          {categories.map((category, index) => (
            <div
              key={category.id}
              className="border-gray-100 dark:border-gray-700 "
            >
              <h2>
                {index + 1}. {category.name}
              </h2>
              <div className="mt-2">{category.description}</div>
              <CategoryQuestions
                number={index + 1}
                category={category}
                questions={questions}
              />
            </div>
          ))}
        </div>
      </AssessmentQuestionContext.Provider>
    </Page>
  );
};

export default AssessmentFollow;
