import { Link, useParams } from "react-router-dom";
import CategoryQuestions from "./AssessmentQuestions";
import AssessmentQuestion from "./AssessmentQuestion";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Button from "components/Button";
import { useModals } from "components/Modal";
import ConfirmSubmitView from "./ConfirmSubmitView";
import { useContext, useEffect } from "react";
import { PageContext } from "components/Page";

const AssessmentCategory = ({ assessment }) => {
  const { index } = useParams();
  const confirmModal = useModals();

  const { pageRef } = useContext(PageContext);

  useEffect(() => {
    if (pageRef.current) pageRef.current.scrollTo(0, 0);
  }, [index, pageRef]);

  function submitHandler() {
    confirmModal.present({
      title: "Submit 提交",
      center: true,
      children: (
        <ConfirmSubmitView
          assessment={assessment}
          dismiss={confirmModal.dismiss}
        />
      ),
    });
  }

  const indexInt = parseInt(index);
  const totalCategories = assessment.category.children.edges.filter(
    ({ node }) => node.requiredSupplier,
  ).length;
  const category = assessment.category.children.edges[indexInt - 1].node;

  const categoryQuestions = assessment.questions.edges.filter((question) => {
    return question.node.question.category.id === category.id;
  });

  return (
    <div className="">
      <div className="px-8 xl:px-10 space-x-4 py-4 sticky top-0 z-10 flex justify-between items-center backdrop-blur bg-opacity-80 bg-gray-100 dark:bg-gray-900 border-b dark:border-gray-800 dark:bg-opacity-80">
        <h4>
          {category.indexNumber}. {category.name}
        </h4>
        <div className="flex items-center justify-between space-x-4 xl:space-x-6">
          <Link
            to={`../${indexInt - 1}`}
            className="flex items-center space-x-2"
          >
            <span>
              <BsChevronLeft size={18} />
            </span>
            <span>Prev</span>
          </Link>

          <div className="opacity-70">
            {indexInt + 1} / {totalCategories + 1}
          </div>

          {indexInt > totalCategories - 1 ? (
            assessment.status === "SELF_ASSESSMENT" ? (
              <Button onClick={submitHandler}>Submit 提交</Button>
            ) : null
          ) : (
            <Link
              to={`../${indexInt + 1}`}
              className="flex items-center space-x-2"
            >
              <span>Next</span>
              <span>
                <BsChevronRight size={18} />
              </span>
            </Link>
          )}
        </div>
      </div>

      <div className="m-6 lg:m-8">
        {category.children.edges.length > 0 ? (
          <div className="space-y-8">
            {category.children.edges.map(({ node }) => (
              <div key={node.id} className="card">
                <h5 className="pb-6 border-b dark:border-gray-700">
                  {node.indexNumber}. {node.name}
                </h5>
                <CategoryQuestions category={node} assessment={assessment} />
              </div>
            ))}
          </div>
        ) : (
          <div className="card py-2">
            {categoryQuestions.map(({ node }, questionIndex) => (
              <div
                key={node.id}
                className={`${questionIndex > 0 ? "border-t" : ""} border-gray-100 dark:border-gray-700 py-6`}
              >
                <AssessmentQuestion
                  number={index}
                  questionIndex={questionIndex}
                  assessmentQuestion={node}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentCategory;
