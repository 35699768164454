import Input from "components/Input";
import ImprovementView from "./ImprovementView";
import useAssessment from "./useAssessment";

function hasChoices(question) {
  return (
    question.answerType === "SINGLE_CHOICE" ||
    question.answerType === "MULTIPLE_CHOICES"
  );
}

const AssessmentQuestion = ({ assessmentQuestion, number }) => {
  const { assessmentStatus } = useAssessment();
  const numberListCount = assessmentQuestion.question.numberListCount;
  const answer = {
    answerNumbers: assessmentQuestion.answerNumbers.length
      ? assessmentQuestion.answerNumbers
      : Array(numberListCount).fill(0),
    answerText: assessmentQuestion.answerText,
    answerChoices: assessmentQuestion.answerChoices,
  };

  return (
    <div className="relative card">
      <div className="flex justify-between space-x-4">
        <div>
          <label htmlFor="">{number} Question 问题 :</label>{" "}
          <span className="whitespace-pre-wrap">
            {assessmentQuestion.question.question}
          </span>
          {assessmentQuestion.question.answerType === "MULTIPLE_CHOICES" ? (
            <span className=" opacity-70">(Multiple Choice)</span>
          ) : assessmentQuestion.question.answerType === "SINGLE_CHOICE" ? (
            <span className=" opacity-70">(Single Choice)</span>
          ) : null}
        </div>
      </div>

      {hasChoices(assessmentQuestion.question) ? (
        <div className="my-2 space-y-2 ">
          {assessmentQuestion.question.choices.map((choice) => (
            <div
              key={choice.id}
              className={`flex items-baseline space-x-3 group`}
            >
              <div
                className={`w-6 h-6 border dark:border-gray-700 rounded-full flex items-center justify-center flex-shrink-0 text-xs
                ${
                  answer.answerChoices.includes(choice.choice)
                    ? "bg-blue-500  group-active:bg-blue-100 text-white group-active:text-gray-800 dark:bg-blue-600 dark:text-white dark:group-active:text-gray-400 dark:group-active:bg-gray-800"
                    : "group-active:bg-blue-600"
                }
              `}
              >
                {choice.choice}
              </div>
              <div>{choice.content}</div>
            </div>
          ))}
        </div>
      ) : assessmentQuestion.question.answerType === "TEXT" ? (
        <div className="mt-2">{answer.answerText}</div>
      ) : numberListCount ? (
        <div className="mt-2">
          <div className="flex space-x-3 items-center">
            <label htmlFor="">Answer:</label>
            {answer.answerNumbers.map((i, index) => (
              <div key={index}>
                <Input disabled value={i} className="text-center" />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex items-baseline mt-1 space-x-2">
          <label htmlFor="">Answer: </label>
          <div>{assessmentQuestion.answer}</div>
        </div>
      )}

      {assessmentQuestion.uploadedFiles.length > 0 ? (
        <div className="text-xs space-y-2 font-semibold mt-4">
          {assessmentQuestion.uploadedFiles.map((url, index) => (
            <div key={index} className="flex items-center space-x-2">
              <a href={url} target="_blank" rel="noreferrer">
                {url}
              </a>
            </div>
          ))}
        </div>
      ) : null}

      {assessmentStatus === "REVIEWING" ? null : (
        <div className="border-t dark:border-gray-700 pt-4 mt-6">
          <h6 className="text-sm opacity-70">Improvement 整改建议</h6>
          <div className="mb-4 whitespace-pre-wrap">
            {assessmentQuestion.improvementNote}
          </div>
          <ImprovementView assessmentQuestion={assessmentQuestion} />
        </div>
      )}
    </div>
  );
};

export default AssessmentQuestion;
