import { parseError } from "Apollo";

const Errors = ({ error }) => {
  return (
    <div className="flex justify-center flex-col items-center z-30 text-pink-500 font-bold flex-1">
      {parseError(error)}
    </div>
  );
};

export default Errors;
