import AssessmentQuestion from "./AssessmentQuestion";

const CategoryQuestions = ({ category, assessment }) => {
  const categoryQuestions = assessment.questions.edges.filter(({ node }) => {
    return node.question.category.id === category.id;
  });

  return (
    <div>
      {category.children ? (
        <div className="mt-4">
          {category.children.edges.map(({ node }) => (
            <div key={node.id}>
              <h6>{node.name}</h6>
              <CategoryQuestions
                key={node.id}
                category={node}
                assessment={assessment}
              />
            </div>
          ))}
        </div>
      ) : null}

      <div>
        {categoryQuestions.map(({ node }, questionIndex) => (
          <div
            key={node.id}
            className={`${questionIndex > 0 ? "border-t" : ""} border-gray-100 dark:border-gray-700 py-6`}
          >
            <AssessmentQuestion assessmentQuestion={node} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryQuestions;
