import React from "react";

const Status = ({ status, active = true }) => {
  let styles = {
    OVER_DUE: "bg-red-500",
    PREPARE_PAYMENT: "bg-orange-500",
    PARTIALLY_PAID: "bg-teal-500",
    FULLY_PAID: "bg-green-500",
    PENDING: "bg-blue-600",
    PASS: "bg-green-500",
    FAIL: "bg-red-600",
    DRAFT: "bg-gray-500",
    WAITING_TO_CONFIRM: "bg-teal-500",
    PLANNING: "bg-green-500",
    HANDLED: "bg-sky-700",
    CANCELED: "bg-red-600",

    SELF_ASSESSMENT: "bg-teal-500",
    REVIEWING: "bg-indigo-500",
    IMPROVING: "bg-orange-500",
    COMPLETED: "bg-sky-700",
  };

  if (!status) return " - ";

  const style = styles[status] || "bg-gray-500";

  const statusString = status.replaceAll("_", " ").toLowerCase();

  return (
    <span
      className={`text-xs rounded-full inline-block text-gray-100 px-4 py-1 scale-90 capitalize whitespace-nowrap ${style} ${active ? "" : " opacity-40"}`}
    >
      {statusString}
    </span>
  );
};

export default Status;
